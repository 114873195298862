import React from 'react';
import cn from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import { MutableSnapshot, RecoilRoot, useRecoilValue } from 'recoil';
import { ModelColorPickerState, ModelColourPicker } from './store';
import { Button, SvgIcon } from '~/common/components/ui-elements';
import { ColourOption, Variant } from '~/common/models';
import {
  useDismiss,
  useRole,
  useFloating,
  useInteractions,
  FloatingPortal,
  FloatingArrow,
  autoUpdate,
  arrow,
  offset,
  flip,
  shift,
  useClick,
} from '@floating-ui/react';
import { ColourSwatch } from '~/common/components';
import styles from './index.module.scss';

export const ModelColourPickerMacro = React.memo<ModelColourPicker>((props) => {
  const initialize = ({ set }: MutableSnapshot) => {
    set(ModelColorPickerState, props);
  };

  return (
    <RecoilRoot initializeState={initialize}>
      <ModelColourPickerContent />
    </RecoilRoot>
  );
});

export const ModelColourPickerContent = React.memo(() => {
  const content = useRecoilValue(ModelColorPickerState);

  const [activeColour, setActiveColour] = React.useState<ColourOption | null>(
    null
  );
  const [selectedVariant, setSelectedVariant] = React.useState<Variant | null>(
    null
  );

  const images = React.useMemo(() => {
    const imgs: string[] = [];
    if (!!activeColour) {
      if (!!activeColour.frontImageUrl) {
        imgs.push(activeColour.frontImageUrl);
      }
      if (!!activeColour.rearImageUrl) {
        imgs.push(activeColour.rearImageUrl);
      }
    }
    return imgs;
  }, [activeColour]);

  React.useEffect(() => {
    if (!!selectedVariant) {
      if (!!selectedVariant.colourOptions?.length) {
        setActiveColour(selectedVariant.colourOptions[0]);
      }
    }
  }, [selectedVariant]);

  return content.isVisible ? (
    <div className={styles.modelColourPicker}>
      <div className={styles.modelVariantSelect}>
        <p>Let&apos;s choose the colour of your </p>
        <SelectedVariant
          options={content?.variants ?? []}
          onSelect={setSelectedVariant}
        />
      </div>

      <div className={styles.imageContainer}>
        {/* <img
          className={styles.variantColour}
          src={activeColour?.frontImageUrl}
          alt={selectedVariant?.name}
        /> */}

        <div>
          <Carousel
            showArrows
            swipeable
            autoPlay={false}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={false}
            showIndicators={false}
            renderArrowNext={(onClick, hasNext) =>
              hasNext && (
                <SvgIcon
                  onClick={onClick}
                  className={cn(styles.arrow, styles.renderArrowNext)}
                  type="chevronRight"
                  size={1.6}
                />
              )
            }
            renderArrowPrev={(onClick, hasPrev) =>
              hasPrev && (
                <SvgIcon
                  onClick={onClick}
                  className={cn(styles.arrow, styles.renderArrowPrev)}
                  type="chevronLeft"
                  size={1.6}
                />
              )
            }
          >
            {images.map((img, index) => (
              <img
                key={index}
                className={styles.variantColour}
                alt={activeColour?.name}
                src={img}
              />
            ))}
          </Carousel>
        </div>
      </div>

      <div className={styles.colourContainer}>
        <div className={styles.colourName}>
          <div>Colour</div>
          <div>{activeColour?.name}</div>
        </div>
        <div className={styles.colourVariation}>
          {selectedVariant?.colourOptions?.map((colour, index) => (
            <ColourSwatch
              key={index}
              colour={colour}
              isActive={activeColour?.colourOptionId === colour.colourOptionId}
              onClick={() => {
                setActiveColour(colour);
              }}
              delay={(index + 1) * 30}
            />
          ))}
        </div>
      </div>

      <div
        className={styles.colourDisclaimer}
        dangerouslySetInnerHTML={{
          __html: content.colourDisclaimer as string,
        }}
      />

      <div className={styles.ctaWrapper}>
        <div>
          {!content?.bookTestDriveCta?.ctaHidden && (
            <Button
              type="primary"
              href={content?.bookTestDriveCta?.ctaUrl?.url}
              className={styles.colourCta}
            >
              {content?.bookTestDriveCta?.ctaUrl?.name}
            </Button>
          )}
          {!content?.downloadBrochureCta?.ctaHidden && (
            <Button
              type="bordered"
              href={content?.downloadBrochureCta?.ctaUrl?.url}
              className={styles.colourCta}
            >
              {content?.downloadBrochureCta?.ctaUrl?.name}
            </Button>
          )}
        </div>

        {!content?.buildYourOwnCta?.ctaHidden && (
          <Button
            type="text"
            href={content?.buildYourOwnCta?.ctaUrl?.url}
            className={styles.colourCta}
          >
            {content?.buildYourOwnCta?.ctaUrl?.name}
          </Button>
        )}
      </div>
    </div>
  ) : null;
});

type SelectedVariantProps = {
  options: Variant[];
  onSelect: (variant: Variant) => void;
};

const SelectedVariant = React.memo<SelectedVariantProps>(
  ({ options, onSelect }) => {
    const arrowRef = React.useRef(null);

    const [selectedVariant, setSelectedVariant] = React.useState<string>('');
    const [isOpen, setIsOpen] = React.useState(false);

    const { refs, floatingStyles, context } = useFloating({
      open: isOpen,
      placement: 'bottom',
      onOpenChange: setIsOpen,
      whileElementsMounted: autoUpdate,
      middleware: [
        shift(),
        offset(5),
        arrow({ element: arrowRef }),
        flip({ fallbackAxisSideDirection: 'end' }),
      ],
    });

    const dismiss = useDismiss(context);
    const click = useClick(context);
    const role = useRole(context, { role: 'listbox' });
    const { getReferenceProps, getFloatingProps } = useInteractions([
      dismiss,
      click,
      role,
    ]);

    React.useEffect(() => {
      if (options.length > 0) {
        handleSelect(options[0]);
      }
    }, [options]);

    const handleSelect = (variant: Variant) => {
      setSelectedVariant(variant.name);
      onSelect(variant);
      setIsOpen(false);
    };

    return (
      <>
        <p
          ref={refs.setReference}
          {...getReferenceProps()}
          className={cn(styles.disclaimerTrigger, styles.activeVariant)}
        >
          {selectedVariant}
          <SvgIcon type="chevronDown" color="#1637a0" size={0.9} />
        </p>

        <FloatingPortal>
          {isOpen && (
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className={styles.dropdownOptions}
            >
              <FloatingArrow ref={arrowRef} context={context} fill="#fff" />
              <ul>
                {options.map((option) => (
                  <li
                    key={option.pimIdentifier}
                    onClick={() => handleSelect(option)}
                    className={
                      selectedVariant === option.name ? styles.selected : ''
                    }
                  >
                    {option.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </FloatingPortal>
      </>
    );
  }
);
